<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
            <div class="header-settings row-space-tbf">
                <div class="space-left"></div>
                <div class="content">
                    <div class="actions">
                        <div class="filter-dropdown-header dropdown">
                            <button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text">{{ selectedYear.year }}</span>
                                <div class="icon-filter"><icon-arrow /></div>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownFilter">
                                <template v-for="year in years">
                                    <div class="dropdown-item" @click.stop="selectYear(year)" v-bind:class="{active: selectedYear == year}" :key="'year-' + year.year">
                                        <div class="checkbox">
                                            <div class="checkmark"></div><span class="text">{{ year.year }}</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <!-- <button class="btn-tbf blue center" v-bind:class="{'only-icon': $resize && $mq.below(850)}">
                            <span class="text" v-if="$resize && $mq.above(850)">{{ $t('report_users.download_xls') }}</span>
                            <icon-download class="icon" v-else />
                        </button> -->
                    </div>
                </div>
                <div class="space-right"></div>
            </div>
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content monthly-settings">
					<div class="list">
						<div class="item" v-for="month in months" @click="viewItem(month)" v-bind:class="{active: monthSelected.id == month.id}">
							<div class="name">{{ moment().month(month.month-1).format("MMMM").charAt(0).toUpperCase() + moment().month(month.month-1).format("MMMM").slice(1) }} <br> <span class="description">{{ $t('salary.dashboard.working_days') }} {{month.working_days}}</span> </div>
							<div class="actions">
								<button @click.stop="editItem(month)"><icon-edit /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!months.length">
							{{ $t('nomenclature.bank.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box bg-white" v-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-calendar /></div>
								<div class="name">{{ moment().month(monthSelected.month-1).format("MMMM").charAt(0).toUpperCase() + moment().month(monthSelected.month-1).format("MMMM").slice(1) }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="viewItem(monthSelected)">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.working_days') }}*</label>
                                        <div v-if="$v.month.working_days.$error && $v.month.working_days.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
                                        <div v-if="!$v.month.working_days.minValue || !$v.month.working_days.maxValue" class="error-msg">{{ $t('validator.monthly_settings_working_days')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.working_days.$error}">
										<div class="icon-right" v-if="month.working_days != ''" @click.stop="month.working_days = ''"><icon-close class="icon-clear" /></div>
										<input type="number" onkeydown="if(event.key==='.' || event.key===',' || event.key==='-'){event.preventDefault();}" :placeholder="$t('nomenclature.monthly_settings.working_days_ph')" class="input-text no-icon" v-model="month.working_days">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.minimum_salary') }}*</label>
                                        <div v-if="$v.month.minimum_salary.$error && $v.month.minimum_salary.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
                                        <div v-if="!$v.month.minimum_salary.minValue" class="error-msg">{{ $t('validator.monthly_settings_salary_min_value')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.minimum_salary.$error}">
										<div class="icon-right" v-if="month.minimum_salary != ''" @click.stop="month.minimum_salary = ''"><icon-close class="icon-clear" /></div>
										<input type="number" onkeydown="if(event.key==='.' || event.key===',' || event.key==='-'){event.preventDefault();}" :placeholder="$t('nomenclature.monthly_settings.minimum_salary_ph')" class="input-text no-icon" v-model="month.minimum_salary">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.medium_salary') }}*</label>
                                        <div v-if="$v.month.medium_salary.$error && $v.month.medium_salary.$model == ''" class="error-msg">{{ $t('validator.required')}}</div>
                                        <div v-if="!$v.month.medium_salary.minValue" class="error-msg">{{ $t('validator.monthly_settings_salary_min_value')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.medium_salary.$error}">
										<div class="icon-right" v-if="month.medium_salary != ''" @click.stop="month.medium_salary = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="number" :placeholder="$t('nomenclature.monthly_settings.medium_salary_ph')" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="month.medium_salary">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.budget_fundamental_medium_gross_salary') }}*</label>
                                        <div v-if="$v.month.budget_fundamental_medium_gross_salary.$error && $v.month.budget_fundamental_medium_gross_salary.$model == ''" class="error-msg">{{ $t('validator.required')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.budget_fundamental_medium_gross_salary.$error}">
										<div class="icon-right" v-if="month.budget_fundamental_medium_gross_salary != ''" @click.stop="month.budget_fundamental_medium_gross_salary = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="number" :placeholder="$t('salary.other_payroll.value-ph')" onkeydown="if(event.key==='.' || event.key===',' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="month.budget_fundamental_medium_gross_salary">
									</div>
								</div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.monthly_settings.meal_voucher') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="month.meal_voucher = 0" v-bind:class="{active: month.meal_voucher === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="month.meal_voucher = 1" v-bind:class="{active: month.meal_voucher === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.meal_voucher_value') }}*</label>
                                        <div v-if="$v.month.meal_voucher_value.$error && $v.month.meal_voucher_value.$model == ''" class="error-msg">{{ $t('validator.required')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.meal_voucher_value.$error}">
										<div class="icon-right" v-if="month.meal_voucher_value != ''" @click.stop="month.meal_voucher_value = ''"><icon-close class="icon-clear" /></div>
										<input step="0.01" type="number" :placeholder="$t('salary.other_payroll.value-ph')" pattern="^\d*(\.\d{0,2})?$" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="month.meal_voucher_value">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.ticket_gift_non_taxable_max_limit') }}*</label>
                                        <div v-if="$v.month.ticket_gift_non_taxable_max_limit.$error && $v.month.ticket_gift_non_taxable_max_limit.$model == ''" class="error-msg">{{ $t('validator.required')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.ticket_gift_non_taxable_max_limit.$error}">
										<div class="icon-right" v-if="month.ticket_gift_non_taxable_max_limit != ''" @click.stop="month.ticket_gift_non_taxable_max_limit = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="number" :placeholder="$t('salary.other_payroll.value-ph')" onkeydown="if(event.key==='.' || event.key===',' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="month.ticket_gift_non_taxable_max_limit">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.ticket_holiday_non_taxable_max_limit') }}*</label>
                                        <div v-if="$v.month.ticket_holiday_non_taxable_max_limit.$error && $v.month.ticket_holiday_non_taxable_max_limit.$model == ''" class="error-msg">{{ $t('validator.required')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.ticket_holiday_non_taxable_max_limit.$error}">
										<div class="icon-right" v-if="month.ticket_holiday_non_taxable_max_limit != ''" @click.stop="month.ticket_holiday_non_taxable_max_limit = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="number" :placeholder="$t('salary.other_payroll.value-ph')" onkeydown="if(event.key==='.' || event.key===',' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="month.ticket_holiday_non_taxable_max_limit">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.monthly_settings.copyright_flat_rate') }}*</label>
                                        <div v-if="$v.month.copyright_flat_rate.$error && $v.month.copyright_flat_rate.$model == ''" class="error-msg">{{ $t('validator.required')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.month.copyright_flat_rate.$error}">
										<div class="icon-right" v-if="month.copyright_flat_rate != ''" @click.stop="month.copyright_flat_rate = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="number" :placeholder="$t('salary.other_payroll.value-ph')" onkeydown="if(event.key==='.' || event.key===',' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="month.copyright_flat_rate">
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon-header"><icon-calendar /></div>
								<div class="name">{{ moment().month(monthSelected.month-1).format("MMMM").charAt(0).toUpperCase() + moment().month(monthSelected.month-1).format("MMMM").slice(1) }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editItem(monthSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
								</div>
							</div>
							<div class="data-item">
								<div class="label">{{ $t('nomenclature.monthly_settings.working_days') }}</div>
								<div class="text">{{ monthSelected.working_days ? monthSelected.working_days : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.minimum_salary') }}</div>
								<div class="text">{{ monthSelected.minimum_salary ? monthSelected.minimum_salary : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.medium_salary') }}</div>
								<div class="text">{{ monthSelected.medium_salary ? monthSelected.medium_salary : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.meal_voucher') }}</div>
								<div class="text">{{ monthSelected.meal_voucher ? $t('general.yes') : $t('general.no')}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.meal_voucher_value') }}</div>
								<div class="text">{{ monthSelected.meal_voucher_value ? monthSelected.meal_voucher_value : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.ticket_gift_non_taxable_max_limit') }}</div>
								<div class="text">{{ monthSelected.ticket_gift_non_taxable_max_limit ? monthSelected.ticket_gift_non_taxable_max_limit : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.copyright_flat_rate') }}</div>
								<div class="text">{{ monthSelected.copyright_flat_rate ? monthSelected.copyright_flat_rate : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.budget_fundamental_medium_gross_salary') }}</div>
								<div class="text">{{ monthSelected.budget_fundamental_medium_gross_salary ? monthSelected.budget_fundamental_medium_gross_salary : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.monthly_settings.ticket_holiday_non_taxable_max_limit') }}</div>
								<div class="text">{{ monthSelected.ticket_holiday_non_taxable_max_limit ? monthSelected.ticket_holiday_non_taxable_max_limit : '-'}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
    import IconArrow from '../../Icons/Arrow'
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconCalendar from '../../Icons/Calendar'
    import IconCalculator from '../../Icons/Calculator'
    import iconUpload from '../../Icons/Upload'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required, requiredIf, minLength, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'

	export default {
		components: {
            IconArrow,
			IconClose,
			IconEdit,
			IconTrash,
			IconCalendar,
            IconCalculator,
            iconUpload,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'view',
                years: [],
                months: [],
                monthSelected: '',
                selectedYear: '',
                month: {
                    working_days: '',
                    minimum_salary: '',
                    medium_salary: '',
                    meal_voucher: 1,
                    meal_voucher_value: '',
                    ticket_gift_non_taxable_max_limit: '',
                    copyright_flat_rate: '',
                    budget_fundamental_medium_gross_salary: '',
                    ticket_holiday_non_taxable_max_limit: '',
                },

				error_message: '',
			}
		},
		async mounted(){
			await this.getYears()

			setTimeout(() => {
				var title = this.$t('nomenclature-sidebar.monthly_settings');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshBanksNomenclature', () => {
				this.viewCrud = 'edit';
				this.getYears()
			})
            this.onlyTwoDecimals()
		},
		validations: {
            month: {
                working_days: {
                    required,
                    minValue: minValue(18),
                    maxValue: maxValue(24)
                },
                minimum_salary: {
                    required,
                    minValue: minValue(1000)
                },
                medium_salary: {
                    required,
                    minValue: minValue(1000)
                },
                meal_voucher_value: {
                    required
                },
                ticket_gift_non_taxable_max_limit: {required},
                copyright_flat_rate: {required},
                budget_fundamental_medium_gross_salary: {required},
                ticket_holiday_non_taxable_max_limit: {required}
            }
		},
		methods: {
            onlyTwoDecimals(){
                $(document).on('keydown', 'input[pattern]', function(e){
                    var input = $(this);
                    var oldVal = input.val();
                    var regex = new RegExp(input.attr('pattern'), 'g');

                    setTimeout(function(){
                        var newVal = input.val();
                        if(!regex.test(newVal)){
                        input.val(oldVal); 
                        }
                    }, 1);
                });
            },
            async getYears() {
				await axios.get(`${this.$auth.user().instance.id}/months`)
				.then(async ({data}) => {
                    this.years = data.data.reduce((unique, o) => {
                        if(!unique.some(obj => obj.year === o.year)) {
                            unique.push(o);
                        }
                        return unique;
                    },[]);
                    this.selectedYear = this.years.find(el => el.year == new Date().getFullYear())
                    await this.getMonthlySettings(this.selectedYear.year)
                    let currentMonth = new Date().getMonth()
                    this.monthSelected = this.months.find(el => el.month-1 == currentMonth)
                    this.viewItem(this.monthSelected)
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
            async selectYear(year) {
                this.selectedYear = year
                this.loaded = false
                await this.getMonthlySettings(year.year)
                let currentMonth = new Date().getMonth()
                this.monthSelected = this.months.find(el => el.month-1 == currentMonth)
                this.viewItem(this.monthSelected)
            },
            async getMonthlySettings(year) {
                await axios.get(`${this.$auth.user().instance.id}/monthly-settings`, {params: {year: year}}).then(({data}) => {
                    this.months = data.data
                }).finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
            },
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
                    let objData = {};

					objData.working_days = this.month.working_days
					objData.minimum_salary = this.month.minimum_salary
					objData.medium_salary = this.month.medium_salary
					objData.meal_voucher = this.month.meal_voucher
					objData.meal_voucher_value = Number(this.month.meal_voucher_value)
                    objData.ticket_gift_non_taxable_max_limit = this.month.ticket_gift_non_taxable_max_limit
                    objData.copyright_flat_rate = this.month.copyright_flat_rate
                    objData.budget_fundamental_medium_gross_salary = this.month.budget_fundamental_medium_gross_salary
                    objData.ticket_holiday_non_taxable_max_limit = this.month.ticket_holiday_non_taxable_max_limit

					if(type == 'update'){
						this.updateMonthlySettings(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			editItem(item){
				this.scrollTop()
				this.viewCrud = 'edit'
				this.monthSelected = item
				this.month.working_days = item.working_days
				this.month.minimum_salary = item.minimum_salary
				this.month.medium_salary = item.medium_salary
				this.month.meal_voucher = item.meal_voucher
				this.month.meal_voucher_value = Number(item.meal_voucher_value)
                this.month.ticket_gift_non_taxable_max_limit = item.ticket_gift_non_taxable_max_limit
                this.month.copyright_flat_rate = item.copyright_flat_rate
                this.month.budget_fundamental_medium_gross_salary = item.budget_fundamental_medium_gross_salary
                this.month.ticket_holiday_non_taxable_max_limit = item.ticket_holiday_non_taxable_max_limit
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			updateMonthlySettings(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/monthly-settings/${this.monthSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getMonthlySettings(this.selectedYear.year)
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.monthSelected = this.months.find(el => el.id == this.monthSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.monthSelected = item
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			},
		}
	};
</script>